import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface TableContextData {
  editingCell: string | null;
  setEditingCell: (cellId: string | null) => void;
}

const BudgetTableContext = createContext<TableContextData | undefined>(undefined);

interface TableProviderProps {
  children: ReactNode;
}

export const BudgetTableProvider = ({ children }: TableProviderProps) => {
  const [editingCell, setEditingCell] = useState<string | null>(null);

  const value = useMemo(() => ({ editingCell, setEditingCell }), [editingCell]);

  return <BudgetTableContext.Provider value={value}>{children}</BudgetTableContext.Provider>;
};

export const useBudgetTableContext = (): TableContextData => {
  const context = useContext(BudgetTableContext);
  if (!context) {
    throw new Error('useBudgetTableContext must be used within a BudgetTableProvider');
  }
  return context;
};
