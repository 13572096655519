import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TablePagination } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { DialogComponent } from '../../../../components/basics/DialogComponent';
import { StyledTableRow } from '../../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../../components/basics/TableHeaderComponent';
import { TableToolbar } from '../../../../components/basics/TableToolbarComponent';
import { PermissionsObj } from '../../../../hooks/useCrudPermissions';
import { ManualInput } from '../../../../models/ManualInput';
import { formatReportValue, formatSimpleDate, labelDisplayedRows } from '../../../../utils/utils';
import { useManualInputsContext } from '../../context/ManualInputsContext';

interface Props {
  onView: (item: ManualInput) => void;
  onEdit: (item: ManualInput) => void;
  manualInputs: any;
  permissions: PermissionsObj;
}

export const ManualInputsTable = ({ onView, onEdit, permissions, manualInputs }: Props) => {
  const { companyGroup } = useManualInputsContext();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ManualInput>('entry_date');
  const [selected, setSelected] = useState<string[]>([]);
  const use_cost_center = companyGroup.use_cost_center;
  const [deleteItem, setDeleteItem] = useState<ManualInput | undefined>(undefined);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);

  const { handleOrderChange, handlePageChange, handleOnChangeRowsPerPage, rowsPerPage, page, deleteManualInput, deleteManyManualInputs } =
    useManualInputsContext();

  const headCells: readonly HeadCell[] = [
    {
      id: 'entry_date',
      align: 'left',
      disablePadding: true,
      disableSorting: false,
      label: 'Data'
    },
    {
      id: 'company',
      align: 'left',
      disablePadding: false,
      disableSorting: false,
      label: 'Empresa'
    },
    {
      id: 'debit_account',
      align: 'left',
      disablePadding: false,
      disableSorting: false,
      label: 'Conta Débito'
    },
    ...(use_cost_center
      ? [
          {
            id: 'debit_cost_center',
            align: 'left',
            disablePadding: false,
            disableSorting: true,
            label: 'Centro de Custo Débito'
          }
        ]
      : []),
    {
      id: 'credit_account',
      align: 'left',
      disablePadding: true,
      disableSorting: false,
      label: 'Conta Crédito'
    },
    ...(use_cost_center
      ? [
          {
            id: 'credit_cost_center',
            align: 'left',
            disablePadding: false,
            disableSorting: true,
            label: 'Centro de Custo Crédito'
          }
        ]
      : []),
    {
      id: 'history',
      align: 'left',
      disablePadding: true,
      disableSorting: false,
      label: 'Histórico'
    },
    {
      id: 'value',
      align: 'right',
      disablePadding: false,
      disableSorting: false,
      label: 'Valor'
    },
    {
      id: 'actions',
      align: 'right',
      disablePadding: false,
      disableSorting: true,
      label: 'Ações'
    }
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleDeleteItem = (item: ManualInput) => {
    setDeleteItem(item);
  };

  const handleDeleteSelectedItems = () => {
    setDeleteMany(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteItem?.id) {
      await deleteManualInput(deleteItem.id);
    } else if (deleteMany) {
      await deleteManyManualInputs(selected);
    }
    setDeleteMany(false);
    setDeleteItem(undefined);
    setSelected([]);
  };

  const handleDeleteCancel = () => {
    setDeleteMany(false);
    setDeleteItem(undefined);
  };

  const handleRequestSort = (property: keyof ManualInput) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = manualInputs?.results.map((item: ManualInput) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    setSelected([]);
  }, [manualInputs]);

  return (
    <>
      <TableToolbar numSelected={selected?.length} onDeleteSelected={handleDeleteSelectedItems} permissions={permissions} />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
          <TableHeaderComponent
            headCells={headCells}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={manualInputs?.count || 0}
          />
          <TableBody>
            {manualInputs && manualInputs?.count > 0 ? (
              <>
                {manualInputs?.results?.map((row: ManualInput, index: number) => {
                  const isItemSelected = isSelected(row?.id!);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      onClick={(event) => handleClick(event, String(row.id))}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox" align="left">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none" align="left">
                        {formatSimpleDate(row?.entry_date)}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none" align="left">
                        {row?.company?.trade_name}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none" align="left">
                        {row.debit_account?.code ? `${row.debit_account?.code} - ${row.debit_account?.description}` : ' - '}
                      </TableCell>
                      {use_cost_center && (
                        <TableCell component="td" scope="row" padding="none" align="left">
                          {row.debit_cost_center || '-'}
                        </TableCell>
                      )}
                      <TableCell component="td" id={labelId} scope="row" padding="none" align="left">
                        {row.credit_account?.code ? `${row.credit_account?.code} - ${row.credit_account?.description}` : ' - '}
                      </TableCell>
                      {use_cost_center && (
                        <TableCell component="td" scope="row" padding="none" align="left">
                          {row.credit_cost_center || '-'}
                        </TableCell>
                      )}
                      <TableCell component="td" id={labelId} scope="row" padding="none" align="left">
                        {row.history}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none" align="right">
                        {formatReportValue(parseFloat(row.value))}
                      </TableCell>
                      <TableCell align="right">
                        {permissions.hasReadPermission && (
                          <Tooltip title="Visualizar">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                onView(row);
                              }}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {permissions.hasUpdatePermission && (
                          <Tooltip title="Editar">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                onEdit(row);
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {permissions.hasDeletePermission && (
                          <Tooltip title="Excluir">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteItem(row as ManualInput);
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </>
            ) : (
              <StyledTableRow>
                <TableCell align="center" colSpan={10}>
                  Nenhum registro encontrado
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(deleteItem || deleteMany) && (
        <DialogComponent
          title={deleteItem ? 'Excluir ajuste' : 'Excluir ajustes'}
          description={deleteItem ? 'Tem certeza que deseja excluir este ajuste?' : 'Tem certeza que deseja excluir estes ajustes?'}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}
      {manualInputs && manualInputs?.count > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 25, 50]}
          component="div"
          count={manualInputs?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={labelDisplayedRows}
          showFirstButton
          showLastButton
        />
      )}
    </>
  );
};
