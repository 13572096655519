import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DialogWithToggleFullscreen } from '../../../../../../components/basics/DialogWithToggleFullscreen';
import { AnalysisChartService } from '../../../../../../services/AnalysisChart.service';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { IAnalysisModal } from '../../../../models/AnalysisModal';
import { useAnalyticsDRETableContext } from '../context/TableContext';
import { GraphComponentDre } from './GraphComponentDre';

interface Props {
  modalData: IAnalysisModal;
  onClose: () => void;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export const ChartModal = ({ modalData, onClose, onMinimize, onToggleModalFullscreen }: Props) => {
  const { filters } = useAnalyticsDRETableContext();
  const charts = modalData?.data;
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean[]>(Array(charts?.length || 0).fill(true));
  const [error, setError] = useState<boolean[]>(Array(charts?.length || 0).fill(false));

  const fetchChartData = async (chart_id: number, index: number) => {
    try {
      const response = await AnalysisChartService.post(chart_id, filters);
      if (response && checkResponseStatus(response)) {
        setChartData((prevData) => {
          const newData = [...prevData];
          newData[index] = response;
          return newData;
        });

        setLoading((prevLoading) => {
          const newLoading = [...prevLoading];
          newLoading[index] = false;
          return newLoading;
        });
      } else {
        throw new Error('Erro na resposta');
      }
    } catch (error: any) {
      console.error(error);
      setError((prevError) => {
        const newError = [...prevError];
        newError[index] = true;
        return newError;
      });
      setLoading((prevLoading) => {
        const newLoading = [...prevLoading];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  useEffect(() => {
    if (charts && charts.length > 0) {
      charts.forEach((chart, index) => {
        fetchChartData(chart.id, index);
      });
    }
  }, [charts]);

  return (
    <DialogWithToggleFullscreen
      open={!!modalData && modalData.visible}
      onClose={onClose}
      title="Gráficos"
      onMinimize={onMinimize}
      fullscreen={modalData.fullscreen}
      toggleFullscreen={onToggleModalFullscreen}
      fullWidth={true}
      maxWidth={'md'}
    >
      {!charts || charts.length <= 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="400px" width="100%">
          <Typography variant="h6">Nenhum gráfico selecionado.</Typography>
        </Box>
      ) : (
        charts.map((chart, index) => (
          <div key={index}>
            {loading[index] ? (
              <Card style={{ marginBottom: '20px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <CardContent sx={{ alignContent: 'center' }}>
                  <Box display="flex" justifyContent="center" alignItems="center" height="400px" width="100%">
                    <CircularProgress />
                  </Box>
                </CardContent>
              </Card>
            ) : error[index] ? (
              <Card style={{ marginBottom: '20px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <CardContent sx={{ alignContent: 'center' }}>
                  <Typography variant="h3" component="div" style={{ marginBottom: '10px', fontWeight: 'bold', textAlign: 'center' }}>
                    {chart.title}
                  </Typography>
                  <Box display="flex" justifyContent="center" alignItems="center" height="400px" width="100%">
                    <Typography variant="h6">Não foi possível carregar o gráfico</Typography>
                  </Box>
                </CardContent>
              </Card>
            ) : (
              <GraphComponentDre data={chartData[index]} />
            )}
          </div>
        ))
      )}
    </DialogWithToggleFullscreen>
  );
};
