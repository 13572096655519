import styled from 'styled-components';
import page404Image from '../../assets/images/media/error-bg.jpg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh; /* Melhor para dispositivos móveis */
  text-align: center;
  background: url(${page404Image}) no-repeat center;
  background-size: cover;
  font-family: Arial, sans-serif;
`;

export const Crack = styled.div`
  margin-top: 40px;
  width: 90%; /* Maior flexibilidade */
  max-width: 500px; /* Limite máximo */
  height: 50px;
  background: url('/assets/crack.png') no-repeat center;
  background-size: contain;
`;

export const Footer = styled.footer`
  margin-top: auto; /* Coloca o footer no final do flex container */
  font-size: 0.9rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: center;
  padding: 10px; /* Melhor espaçamento */
`;
