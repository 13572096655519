import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { AnalyticsAccounts } from '../../../models/AnalyticsAccounts';
import { ManualInput } from '../../../models/ManualInput';
import { AnalyticsAccountService } from '../../../services/AnalyticsAccount.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { ManualInputsFilterFields, useManualInputsContext } from '../context/ManualInputsContext';

interface Props {
  onClose: () => void;
  setNumFilters: (numFilters: number) => void;
}

const getDefaultValues = (filter?: ManualInput): ManualInputsFilterFields => {
  return {
    company_name: filter?.company_name ?? '',
    history: filter?.history ?? '',
    credit_cost_center: filter?.credit_cost_center ?? '',
    debit_cost_center: filter?.debit_cost_center ?? '',
    entry_date_after: filter?.entry_date_after || null,
    entry_date_before: filter?.entry_date_before || null,
    value_min: filter?.value_min?.replace('.', ',') || null,
    value_max: filter?.value_max?.replace('.', ',') || null
  };
};

export const ManualInputsFilterDialog = ({ onClose, setNumFilters }: Props) => {
  const dispatch = useDispatch();

  const {
    filterFields,
    setFilterFields,
    companyGroup,
    setAccountCreditFilter,
    setAccountDebitFilter,
    accountCreditFilter,
    accountDebitFilter
  } = useManualInputsContext();
  const [analyticsAccounts, setAnalyticsAccounts] = useState<SelectOption[]>([]);

  const [selectedDebitAccount, setSelectedDebitAccount] = useState<SelectOption>(accountDebitFilter || ({} as SelectOption));
  const [selectedCreditAccount, setSelectedCreditAccount] = useState<SelectOption>(accountCreditFilter || ({} as SelectOption));

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ManualInputsFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const updateNumFilters = (data: ManualInputsFilterFields) => {
    const count = Object.values(data).filter((value) => value !== undefined && value !== null && value !== '').length;
    setNumFilters(count);
  };

  const onSubmit: SubmitHandler<ManualInputsFilterFields> = async (data: ManualInputsFilterFields) => {
    data = {
      ...data,
      debit_account: selectedDebitAccount?.id,
      credit_account: selectedCreditAccount?.id,
      value_min: data.value_min ? data.value_min.replace('R$ ', '').replace('.', '').replace(',', '.') : null,
      value_max: data.value_max ? data.value_max.replace('R$ ', '').replace('.', '').replace(',', '.') : null
    };
    setAccountCreditFilter(selectedCreditAccount);
    setAccountDebitFilter(selectedDebitAccount);
    setFilterFields({ ...data } as ManualInputsFilterFields);
    updateNumFilters(data);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ManualInputsFilterFields);
    setAccountCreditFilter({} as SelectOption);
    setAccountDebitFilter({} as SelectOption);
    setNumFilters(0);
    onClose();
  };

  const fetchAccounts = async (watchAccountSearch?: string) => {
    try {
      const response = await AnalyticsAccountService.get({ is_analytical: 1, search: watchAccountSearch });
      if (response?.data?.results) {
        const dataAccounts = response?.data?.results;
        setAnalyticsAccounts(
          dataAccounts.map((account: AnalyticsAccounts) => ({
            id: account.id,
            name: `${account.code} - ${account.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Contas',
          severity: 'error'
        })
      );
    }
  };

  const handleSelectDebitAccount = (value: SelectOption) => {
    setSelectedDebitAccount(value);
  };

  const handleSelectCreditAccount = (value: SelectOption) => {
    setSelectedCreditAccount(value);
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company_name">Nome da Empresa</InputLabel>
              <ControlledTextInput
                name="company_name"
                placeholder="Nome da Empresa"
                control={control}
                errorMessage={errors.company_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="history">Histórico</InputLabel>
              <ControlledTextInput
                name="history"
                placeholder="Histórico"
                control={control}
                errorMessage={errors.history?.message?.toString()}
              />
            </Grid>
            {companyGroup?.use_cost_center && (
              <>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="debit_cost_center">Centro de Custo Débito</InputLabel>
                  <ControlledTextInput
                    name="debit_cost_center"
                    placeholder="Centro de Custo Débito"
                    control={control}
                    errorMessage={errors.debit_cost_center?.message?.toString()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="credit_cost_center">Centro de Custo Crédito</InputLabel>
                  <ControlledTextInput
                    name="credit_cost_center"
                    placeholder="Centro de Custo Crédito"
                    control={control}
                    errorMessage={errors.credit_cost_center?.message?.toString()}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="entry_date_after">Data Inicial</InputLabel>
              <ControlledTextInput
                name="entry_date_after"
                placeholder="Data Inicial"
                type="date"
                control={control}
                errorMessage={errors.entry_date_after?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="entry_date_before">Data Final</InputLabel>
              <ControlledTextInput
                name="entry_date_before"
                placeholder="Data Final"
                type="date"
                control={control}
                errorMessage={errors.entry_date_before?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="debit_account">Conta Débito</InputLabel>
              <AutocompleteSearch
                value={selectedDebitAccount}
                defaultValue={accountDebitFilter}
                onSearch={fetchAccounts}
                selectOptions={analyticsAccounts}
                onSelect={(event, value: SelectOption) => handleSelectDebitAccount(value)}
                errorMessage={errors.debit_account?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="credit_account">Conta Crédito</InputLabel>
              <AutocompleteSearch
                value={selectedCreditAccount}
                defaultValue={accountCreditFilter}
                onSearch={fetchAccounts}
                selectOptions={analyticsAccounts}
                onSelect={(event, value: SelectOption) => handleSelectCreditAccount(value)}
                errorMessage={errors.credit_account?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value_min">Valor Mínimo</InputLabel>
              <ControlledTextInput
                name="value_min"
                placeholder="Valor Mínimo"
                control={control}
                currency={true}
                errorMessage={errors.value_min?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value_max">Valor Máximo</InputLabel>
              <ControlledTextInput
                name="value_max"
                placeholder="Valor Máximo"
                control={control}
                currency={true}
                errorMessage={errors.value_max?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
