import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useTheme } from 'styled-components';
import {
  Container,
  Crack,
  Footer,
} from './styles';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const brand = process.env.REACT_APP_LIDERE_BRAND;

  const handleRedirectToHome = () => {
    navigate('/'); 
  };

  return (
    <Container>
      <Typography
        variant="h1"
        component="h2"
        sx={{
          color: theme.palette.primary.main,
          fontSize: 'clamp(5rem, 10vw, 15rem)', 
        }}
      >
        404
      </Typography>
      <Typography variant="h2" component="h2" sx={{ color: theme.palette.primary.main }} fontSize="2rem" margin="20px" >
        PÁGINA NÃO ENCONTRADA!
      </Typography>
      <Typography variant="subtitle1" component="h2" fontSize="1.2rem" sx={{color: theme.palette.text.secondary}} margin="20px" >
        A pagina solicitada não está disponível no momento
      </Typography>
      <Button variant="contained" margin="30px" onClick={handleRedirectToHome}>Voltar para a tela inicial</Button>
      <Crack />
      <Footer>{brand}</Footer>
    </Container>
  );
};

export default PageNotFound;
