import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { ReportFilter } from '../models/ReportFilter';

const ReportFilterUrl = '/reports/report-filters/';

export interface ReportFilterFilterFields extends ReportFilter {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export const ReportFilterService = {
  get: async (filterFields: ReportFilterFilterFields) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ReportFilterUrl, {
        params: {
          ...filterFields
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: ReportFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(ReportFilterUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: ReportFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${ReportFilterUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
