import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Divider, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAnalyticsDREBudget } from '../../../models/AnalyticsDRE';
import { Budget } from '../../../models/Budget';
import { BudgetItemDREService } from '../../../services/BudgetItemDRE.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { BudgetItemFilterPopover } from './BudgetItemFilter';
import BudgetItemTable from './BudgetItemTable';

interface Props {
  editItem?: Budget;
  onClose: () => void;
}

export const BudgetItemDialog = ({ editItem, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [hasUpdate, setHasUpdate] = useState<boolean>(false);
  const [reportDreData, setReportDreData] = useState<IAnalyticsDREBudget>({} as IAnalyticsDREBudget);
  const useCostCenter = editItem?.company?.use_cost_center;

  const [selectedCostCenters, setSelectedCostCenters] = useState<number[] | null>(null);

  const fetchData = async (costCenters: number[] | null = null) => {
    dispatch(showSpinner());
    try {
      const response = await BudgetItemDREService.post(editItem?.id, { cost_centers: costCenters });
      if (response && checkResponseStatus(response)) {
        setReportDreData(response?.data);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleDisplayFiltersPopover = (event?: MouseEvent<HTMLButtonElement> | null) => {
    setFilterAnchorEl(event?.currentTarget || null);
  };

  const handleSetRefresh = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const handleSetHasUpdate = (value: boolean) => {
    setHasUpdate(value);
  };

  const handleRefreshCleanUpdate = () => {
    handleSetRefresh();
    setHasUpdate(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (refresh) {
        await fetchData();
        setRefresh(false);
      }
    })();
  }, [refresh]);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <DialogTitle variant="h4" color="primary">
          Orçamento: {editItem?.company?.company_name} - {editItem?.reference_year}
        </DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {useCostCenter && (
            <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleDisplayFiltersPopover}>
              Filtros
            </Button>
          )}
          <Button variant="text" color="primary" onClick={onClose} startIcon={<CloseIcon />}>
            Fechar
          </Button>
        </Box>
      </Box>
      <Divider />
      <DialogContent sx={{ flex: 1, backgroundColor: theme.palette.background.default }}>
        {hasUpdate && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              mb: 1,
              backgroundColor: '#f7e8cd',
              color: theme.palette.warning.contrastText,
              borderRadius: 1
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <WarningAmberIcon sx={{ fontSize: 18, color: '#895f17' }} />
              <span style={{ fontSize: 12, color: '#895f17' }}>
                Os valores do orçamento foram alterados. Clique no botão para recarregar e atualizar os dados.
              </span>
            </Box>
            <Button
              variant="contained"
              color="warning"
              startIcon={<CachedIcon />}
              onClick={handleRefreshCleanUpdate}
              sx={{
                fontWeight: 'bold',
                textTransform: 'none'
              }}
            >
              Recarregar
            </Button>
          </Box>
        )}
        <BudgetItemTable item={editItem!} reportDreData={reportDreData} setRefresh={handleSetRefresh} setHasUpdate={handleSetHasUpdate} />
      </DialogContent>
      <Divider />
      <BudgetItemFilterPopover
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        onFilter={(costCenters) => {
          setSelectedCostCenters(costCenters);
          fetchData(costCenters);
        }}
      />
    </Dialog>
  );
};
