import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IAnalyticsDREBudget, IAnalyticsDRERowBudget } from '../../../../models/AnalyticsDRE';
import { ApiResults } from '../../../../models/ApiResults';
import { Budget } from '../../../../models/Budget';
import { BudgetTableProvider } from './context/TableContext';
import TableRowRecursive from './TableRowRecursive';

export interface BudgetResult extends ApiResults {
  results: Budget[];
}
interface BudgetItemTableProps {
  item: Budget;
  reportDreData: IAnalyticsDREBudget;
  setRefresh: (value: boolean) => void;
  setHasUpdate: (value: boolean) => void;
}

export const BudgetItemTable = ({ item, reportDreData, setRefresh, setHasUpdate }: BudgetItemTableProps) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1, paddingBottom: '60px' }}>
        <Box>
          <TableContainer component={Paper} sx={{ borderRadius: '4px' }} elevation={1}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: '1px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="12px">
                      Conta Contábil
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" width={100} sx={{ textAlign: 'center' }}>
                      Média Ano Anterior
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" width={100} sx={{ textAlign: 'center' }}>
                      Média dos últimos 6 meses do ano anterior
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" width={100} sx={{ textAlign: 'center' }}>
                      Média dos últimos 3 meses do ano anterior
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="10px" width={100} sx={{ textAlign: 'center' }}>
                      Valor Mês Referência do ano anterior
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Janeiro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Fevereiro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Março
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Abril
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Maio
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Junho
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Julho
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Agosto
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Setembro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Outubro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Novembro
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #c7c7c7'
                    }}
                  >
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Dezembro
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5" fontSize="12px" textAlign={'right'}>
                      Acumulado Ano
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportDreData && (
                  <BudgetTableProvider>
                    {reportDreData?.report?.map((row: IAnalyticsDRERowBudget, index: number) => (
                      <TableRowRecursive key={row.id} row={row} budget={item} setRefresh={setRefresh} setHasUpdate={setHasUpdate} />
                    ))}
                  </BudgetTableProvider>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </Box>
      </Box>
    </>
  );
};

export default BudgetItemTable;
