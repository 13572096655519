import { capitalize, indexOf } from 'lodash';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITableColumn } from '../..';
import { IAnalyticsDRE, IAnalyticsDREFilter } from '../../../../../../models/AnalyticsDRE';
import { CompanyGroup } from '../../../../../../models/CompanyGroup';
import { ReportFilter } from '../../../../../../models/ReportFilter';
import { AnalysisDREService } from '../../../../../../services/AnalysisDRE.service';
import { CompaniesService } from '../../../../../../services/Companies.service';
import { ReportFilterService } from '../../../../../../services/ReportFilter.service';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../../../store/store';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { formatColumnNameIntoDate } from '../../../../../../utils/utils';
import { ManualInputsModal } from '../../../../components/ManualInputsModal';
import { IAnalysisModal, IAnalysisModalType } from '../../../../models/AnalysisModal';
import { IManualInputData } from '../../../../models/ManualInputData';
import { OpenLevels } from '../../../../models/OpenLevels';
import { ChartModal } from '../ChartModal';
import { DetailsModal } from '../DetailsModal';
import { MinimizedModalBar } from '../MinimizedModalBar';

interface ContextData {
  openLevels: OpenLevels;
  toggleLevel: (level: number) => void;
  handleOpenNewDetailsModal: (data: any) => void;
  filters: IAnalyticsDREFilter;
  handleOpenNewManualInputsModal: (data: IManualInputData) => void;
  filterUseBudget: boolean;
  data: IAnalyticsDRE;
  companyGroup: CompanyGroup;
  reportFilter: ReportFilter;
  loadedFilters: boolean;
  columns: ITableColumn[];
  toggleColumnVisibility: (columnId: string) => void;
  toggleModalFullscreen?: (modal: IAnalysisModal) => void;
  handleSetFilters: (filters: IAnalyticsDREFilter) => void;
  handleOpenChart: (charts_ids: number[]) => void;
  handleUseBudget: (value: boolean) => void;
  handleShowInputsManual: (value: boolean) => void;
  showInputsManual: boolean;
  retroactivePeriods: string[];
  yearlyFilter?: string[];
}

const AnalyticsDRETableContext = createContext<ContextData | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export function AnalyticsDREProvider({ children }: ProviderProps) {
  const dispatch = useDispatch();
  const { companiesIds: companiesContext } = useSelector((state: RootState) => state.companiesContext);

  const [modalList, setModalList] = useState<any[]>([]);
  const [filters, setFilters] = useState<IAnalyticsDREFilter>({} as IAnalyticsDREFilter);

  const [data, setData] = useState<IAnalyticsDRE>({} as IAnalyticsDRE);
  const [columns, setColumns] = useState<ITableColumn[]>([]);
  const [companyGroup, setCompanyGroup] = useState<CompanyGroup>({} as CompanyGroup);
  const [loadedFilters, setLoadedFilters] = useState<boolean>(false);
  const [reportFilter, setReportFilter] = useState<ReportFilter>({} as ReportFilter);
  const [showInputsManual, setShowInputsManual] = useState<boolean>(false);
  const [filterUseBudget, setFilterUseBudget] = useState(false);

  const MINIMIZED_MODALS = modalList.filter((modal) => !modal.visible);
  const RETROACTIVE_PERIODS =
    filters && filters.period_filter
      ? filters.period_filter.filter((filter) => filter.period_retroactive && filter.year).map((filter) => filter.year as string)
      : [];

  const YEARLY_FILTER = filters.period_filter?.filter((item) => item.type === 'yearly').map((item) => item.year);

  const [openLevels, setOpenLevels] = useState<OpenLevels>({
    1: true,
    2: true,
    3: true,
    4: true
  });

  const handleUseBudget = (value: boolean) => {
    setFilterUseBudget(value);
  };

  const toggleLevel = (level: number) => {
    setOpenLevels((prevLevels) => ({
      ...prevLevels,
      [level]: !prevLevels[level]
    }));
  };

  const handleShowInputsManual = (value: boolean) => {
    setShowInputsManual(value);
  };

  const handleOpenChart = async (selectedCharts: { id: number; title: string }[]) => {
    const newModal = {
      title: 'Gráficos',
      visible: true,
      fullscreen: false,
      type: 'chart' as IAnalysisModalType,
      data: selectedCharts
    };

    setModalList((prevModalList) => [...prevModalList, newModal]);
  };

  const handleOpenNewDetailsModal = (data: IManualInputData) => {
    const newModal = {
      title: 'Detalhamento ' + formatColumnNameIntoDate(data.key) + ' - ' + data.row.description,
      visible: true,
      fullScreen: false,
      type: 'details' as IAnalysisModalType,
      data: data
    };

    setModalList((prevModalList) => [...prevModalList, newModal]);
  };

  const handleOpenNewManualInputsModal = (data: IManualInputData) => {
    const newModal = {
      title: 'Lançamento Man. ' + formatColumnNameIntoDate(data.key) + ' - ' + capitalize(data.row.description) + ' - ' + data.columnLabel,
      visible: true,
      fullScreen: false,
      type: 'manual_inputs' as IAnalysisModalType,
      data: data
    };

    setModalList((prevModalList) => [...prevModalList, newModal]);
  };

  const handleCloseModal = (modal: any) => {
    setModalList((prevModalList) => {
      const newList = [...prevModalList];
      const index = indexOf(newList, modal);
      if (index > -1) {
        newList.splice(index, 1);
      }
      return newList;
    });
  };

  const handleSubmitManualInput = async (modal: IAnalysisModal) => {
    setModalList((prevModalList) => {
      const newList = [...prevModalList];
      const index = indexOf(newList, modal);
      if (index > -1) {
        newList.splice(index, 1);
      }
      return newList;
    });
    await fetchData();
  };

  const handleDisplayModal = (modal?: IAnalysisModal) => {
    setModalList((prevModalList) => {
      const index = indexOf(prevModalList, modal);
      return prevModalList.map((modal, i) => {
        if (i === index) {
          return { ...modal, visible: true };
        } else {
          return { ...modal, visible: false };
        }
      });
    });
  };

  const handleMinimize = async () => {
    setModalList((prevModalList) =>
      prevModalList.map((modal) => ({
        ...modal,
        visible: false
      }))
    );
  };

  const toggleColumnVisibility = (columnId: string) => {
    setColumns((prevColumns) => prevColumns.map((column) => (column.id === columnId ? { ...column, visible: !column.visible } : column)));
  };

  const toggleModalFullscreen = (modal: IAnalysisModal) => {
    setModalList((prevModalList) => {
      const newList = [...prevModalList];
      const index = indexOf(newList, modal);
      if (index > -1) {
        newList[index].fullscreen = !newList[index].fullscreen;
      }
      return newList;
    });
  };

  const handleSetFilters = (filters: IAnalyticsDREFilter) => {
    setFilters(filters);
  };

  const createTableColumns = (data) => {
    if (!data.metadata?.columns) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Não foi possível carregar os dados da análise.',
          severity: 'error'
        })
      );
      return;
    }

    try {
      const insertedYears = new Set();

      const newColumns = data.metadata.columns.reduce((acc, key, index) => {
        const year = key.substring(0, 4) || '';
        const isLastColumn = index === data.metadata.columns.length - 1;
        const nextYear = !isLastColumn ? data.metadata.columns[index + 1].substring(0, 4) : null;
        const isEndOfYear = nextYear !== year;

        // Adiciona colunas totais
        if (!YEARLY_FILTER?.includes(year)) {
          acc.push({
            id: key,
            name: formatColumnNameIntoDate(key),
            visible: true,
            type: 'totals',
            index: acc.length,
            year
          });
        }

        // Adiciona colunas acumuladas e médias
        if (!insertedYears.has(year) && data.metadata.years.includes(year) && (isEndOfYear || isLastColumn)) {
          acc.push(
            {
              id: `${year}_accumulated`,
              name: `Acum./${year}`,
              type: 'accumulated',
              visible: true,
              index: acc.length,
              year
            },
            {
              id: `${year}_average`,
              name: `Média/${year}`,
              type: 'average',
              visible: true,
              index: acc.length,
              year
            }
          );

          if (!RETROACTIVE_PERIODS.includes(year)) {
            acc.push({
              id: `${year}_accumulated_percent`,
              name: `AV.Acum./${year}`,
              type: 'accumulated_by_year_percent',
              visible: true,
              index: acc.length,
              year
            });
          }

          if (RETROACTIVE_PERIODS.includes(year)) {
            acc.push({
              id: `${year}_average_percent`,
              name: `AV.MÉDIA/${year}`,
              type: 'averages_percent',
              visible: true,
              index: acc.length,
              year
            });
          }

          insertedYears.add(year);
        }

        return acc;
      }, []);

      setColumns(newColumns);
    } catch (error) {
      console.error('Erro ao criar colunas:', error);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Ocorreu um erro ao processar as colunas da tabela.',
          severity: 'error'
        })
      );
    }
  };

  const fetchData = async () => {
    dispatch(showSpinner());

    try {
      const response = await AnalysisDREService.post(filters);
      if (response && checkResponseStatus(response)) {
        setData(response?.data);
        createTableColumns(response?.data);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchCompanyGroup = async () => {
    try {
      const response = await CompaniesService.getOne(companiesContext[0]);
      if (response && checkResponseStatus(response)) {
        setCompanyGroup(response?.data?.group);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const fetchReportFilters = async () => {
    try {
      const response = await ReportFilterService.get({ type: 'DRE' });
      if (response) {
        setReportFilter(response?.data);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  //ouvir as alteracoes do redux e fazer a busca novamente com as novas empresas.
  useEffect(() => {
    if (companiesContext && companiesContext.length > 0 && Object.entries(filters).length > 0) {
      setData({} as IAnalyticsDRE);
      fetchData();
    }
    fetchCompanyGroup();
  }, [companiesContext, filters]);

  useEffect(() => {
    const loadData = async () => {
      await fetchReportFilters();
      setLoadedFilters(true);
    };
    if (loadedFilters) return;
    loadData();
  }, []);

  const value = useMemo(
    () => ({
      openLevels,
      toggleLevel,
      handleOpenNewDetailsModal,
      handleOpenNewManualInputsModal,
      filters,
      filterUseBudget,
      data,
      loadedFilters,
      companyGroup,
      reportFilter,
      columns,
      toggleColumnVisibility,
      handleOpenChart,
      handleSetFilters,
      handleUseBudget,
      handleShowInputsManual,
      showInputsManual,
      retroactivePeriods: RETROACTIVE_PERIODS, //identifica periodos retroativos para modificar cores da tabela
      yearlyFilter: YEARLY_FILTER //identifica filtros por periodo anual para modificar estrutura da tabela
    }),
    [openLevels, filters, data, columns, showInputsManual, companyGroup, loadedFilters]
  );

  return (
    <AnalyticsDRETableContext.Provider value={value}>
      {modalList?.map((modal) => {
        const commonProps = {
          visible: modal.visible,
          key: modal.title,
          modalData: modal,
          onClose: () => handleCloseModal(modal),
          onMinimize: () => handleMinimize(),
          onToggleModalFullscreen: () => toggleModalFullscreen(modal)
        };

        switch (modal.type) {
          case 'details':
            return <DetailsModal {...commonProps} />;
          case 'manual_inputs':
            return <ManualInputsModal {...commonProps} onSubmit={() => handleSubmitManualInput(modal)} companyGroup={companyGroup} />;
          default:
            return <ChartModal {...commonProps} />;
        }
      })}
      <MinimizedModalBar
        modalList={MINIMIZED_MODALS}
        onDisplayModal={(modal) => handleDisplayModal(modal)}
        onCloseModal={(modal) => handleCloseModal(modal)}
      />
      {children}
    </AnalyticsDRETableContext.Provider>
  );
}

// Hook personalizado para usar o Level Context
export function useAnalyticsDRETableContext(): ContextData {
  const context = useContext(AnalyticsDRETableContext);
  if (!context) {
    throw new Error('useAnalyticsDRETableContext must be used within a AnalyticsDREProvider');
  }
  return context;
}
