import { CircularProgress, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogWithToggleFullscreen } from '../../../../../../components/basics/DialogWithToggleFullscreen';
import { StyledTableRow } from '../../../../../../components/basics/StyledTableRow';
import { AccountingEntry } from '../../../../../../models/AccountingEntry';
import { AccountingEntryMetadata } from '../../../../../../models/AccountingEntryMetadata';
import { ApiResults } from '../../../../../../models/ApiResults';
import { AccountingEntryService } from '../../../../../../services/AccountingEntry.service';
import { AccountingEntryMetadataService } from '../../../../../../services/AccountingEntryMetadata.service';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { formatSimpleDate, getStartAndFinalDate } from '../../../../../../utils/utils';
import { IAnalysisModal } from '../../../../models/AnalysisModal';

interface Props {
  modalData: IAnalysisModal;
  onClose: () => void;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export interface AccountingEntryMetadataFilterFields extends AccountingEntryMetadata {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  entry_date_after?: string;
  entry_date_before?: string;
  account?: number;
  parent_account?: number;
  analytics?: number;
}
export interface AccountingEntryFilterFields extends AccountingEntry {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  entry_date_after?: string;
  entry_date_before?: string;
}
export interface AccountEntryResult extends ApiResults {
  results: AccountingEntry[];
}

export const DetailsModal = ({ modalData, onClose, onMinimize, onToggleModalFullscreen }: Props) => {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [page, setPage] = useState<number>(0);
  const [accountingEntries, setAccountingEntries] = useState<AccountEntryResult | undefined>(undefined);
  const [accountingEntriesMetadata, setAccountingEntriesMetadata] = useState<AccountingEntryMetadata | undefined>(undefined);
  const [isLoadingTotals, setIsLoadingTotals] = useState<boolean>(true);

  const account_description = modalData?.data?.row?.description;
  let row_id = modalData?.data?.row?.id;
  let analytic = modalData?.data?.row?.analytic;
  let entry_date = modalData?.data?.key;
  const { startDate, finalDate } = getStartAndFinalDate(entry_date);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`;
  };

  const fetchDetailsData = async () => {
    dispatch(
      showSpinner({
        message: 'Buscando dados do detalhamento...'
      })
    );

    const filters: AccountingEntryFilterFields = {
      entry_date_after: startDate,
      entry_date_before: finalDate
    };

    const { level } = modalData.data.row;

    if (level === 4) {
      filters.account = row_id;
      filters.analytics = analytic;
    } else if (level === 3) {
      filters.parent_account = row_id;
      filters.analytics = analytic;
    } else if (level <= 2) {
      filters.analytics = row_id;
    }

    try {
      const response = await AccountingEntryService.get(filters, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setAccountingEntries(response.data);
      }
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar dados', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchTotalDetails = async () => {
    setIsLoadingTotals(true);
    const filters: AccountingEntryMetadataFilterFields = {
      entry_date_after: startDate,
      entry_date_before: finalDate
    };

    const { level } = modalData.data.row;

    if (level === 4) {
      filters.account = row_id;
      filters.analytics = analytic;
    } else if (level === 3) {
      filters.parent_account = row_id;
      filters.analytics = analytic;
    } else if (level <= 2) {
      filters.analytics = row_id;
    }

    try {
      const response = await AccountingEntryMetadataService.get(filters, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setAccountingEntriesMetadata(response.data);
      }
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar dados', severity: 'error' }));
    } finally {
      setIsLoadingTotals(false);
    }
  };

  useEffect(() => {
    fetchDetailsData();
  }, [rowsPerPage, page]);

  useEffect(() => {
    fetchTotalDetails();
  }, []);

  return (
    <DialogWithToggleFullscreen
      open={modalData.visible && !!accountingEntries}
      onClose={onClose}
      title={modalData.title}
      onMinimize={onMinimize}
      fullscreen={modalData.fullscreen}
      toggleFullscreen={onToggleModalFullscreen}
      maxWidth={false}
      fullWidth={false}
    >
      <Divider />
      <TableContainer style={{ overflow: 'auto', maxHeight: 'calc(100vh - 210px)' }}>
        <Table size="small">
          <TableHead>
            <StyledTableRow style={{ backgroundColor: '#d9d9d9' }}>
              <TableCell align="right" colSpan={8} sx={{ fontWeight: 'bold' }}>
                Saldo Anterior: {isLoadingTotals ? <CircularProgress size={10} /> : accountingEntriesMetadata?.previous_balance || '-'}
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableHead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
            <StyledTableRow>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Data</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Empr.</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Lcto.</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Contrapartida</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Histórico</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px', textAlign: 'right' }}>Débito</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px', textAlign: 'right' }}>Crédito</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px', textAlign: 'right' }}>Saldo</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {accountingEntries && accountingEntries?.count > 0 ? (
              <>
                {accountingEntries?.results?.map((row: AccountingEntry, index: number) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell>{formatSimpleDate(row.entry_date)}</TableCell>
                      <TableCell>{row.company_name}</TableCell>
                      <TableCell>{row.entry_number ? row.entry_number : row.is_manual ? 'Ajuste' : '-'}</TableCell>
                      <TableCell>{row.counterpart_description ? row.counterpart_description : '-'}</TableCell>
                      <TableCell>{row.history}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{row.origin == 'D' ? row.entry_value : '-'}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{row.origin == 'C' ? row.entry_value : '-'}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{row.balance}</TableCell>
                    </StyledTableRow>
                  );
                })}
              </>
            ) : (
              <StyledTableRow>
                <TableCell align="center" colSpan={8}>
                  Nenhum registro encontrado.
                </TableCell>
              </StyledTableRow>
            )}
            <StyledTableRow style={{ backgroundColor: '#d9d9d9', position: 'sticky', bottom: 0, width: '100%' }}>
              <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                TOTAL:
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                {isLoadingTotals ? <CircularProgress size={10} /> : accountingEntriesMetadata?.total_debit || '-'}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                {isLoadingTotals ? <CircularProgress size={10} /> : accountingEntriesMetadata?.total_credit || '-'}
              </TableCell>
              <TableCell colSpan={1} />
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {accountingEntries && accountingEntries?.count > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 25, 50]}
          component="div"
          count={accountingEntries?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={labelDisplayedRows}
          showFirstButton
          showLastButton
        />
      )}
    </DialogWithToggleFullscreen>
  );
};
